export const language = [
  {
    code: 'en',
    name: 'English',
    nativeName: 'Englisch',
  },
  {
    code: 'de',
    name: 'German',
    nativeName: 'Deutsch',
  },
];
