





















































import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import BaseImage from '@/components/base/BaseImage.vue';
import BaseRow from '@/components/base/BaseRow.vue';
import BaseTabs from '@/components/base/BaseTabs.vue';
import ImageChangeable from '@/components/ImageChangeable.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import LayoutPage from '@/layouts/LayoutPage.vue';
import Vue, { VueConstructor } from 'vue';
import { isArrayLikeObject as _isArrayLikeObject } from 'lodash';
import { directiveDroppable, UPLOAD_TYPE } from '@/directives/droppable';
import { FILE_MIME_TYPE } from '@/utils/fileTypes';
import { IRouteConfig } from '@/typings/interface/IRouteConfig';
import { mixinComponentUID } from '@/components/mixins/componentUID';
import { mixinModes } from '@/components/mixins/modes';
import { mixinTabList } from '@/components/mixins/tabList';
import { MOrganization } from '@/models/MOrganization';
import { MSettings } from '@/models/MSettings';
import { MUser } from '@/models/MUser';
import { profileRouteShow } from './routes';
import { utIsEmpty } from '@/utils/empty';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'Profile',

  directives: {
    droppable: directiveDroppable,
  },

  components: {
    BaseButtonText,
    BaseImage,
    BaseRow,
    BaseTabs,
    ImageChangeable,
    LayoutHeader,
    LayoutPage,
  },

  mixins: [
    mixinComponentUID,
    mixinModes,
    mixinTabList,
  ],

  data() {
    return {
      imageIsLoading: false,
      profileImageAcceptedMimeTypeList: [
        FILE_MIME_TYPE.JPG,
        FILE_MIME_TYPE.PNG,
      ],
      profileRouteShow,
      showModal: false,
      uploadMode: UPLOAD_TYPE.SINGLE,
      utIsEmpty,
    };
  },

  computed: {
    currentOrganization: {
      get(): MOrganization {
        return MOrganization.query().first();
      },

      set(organization: MOrganization): void {
        MOrganization._update(organization);
      },
    },

    currentSettings() {
      return MSettings.find(this.currentOrganization.SettingsId);
    },

    currentUser(): MUser {
      return MUser.query().first();
    },

    currentUserImageIsPresent(): boolean {
      if (this.currentUser) {
        return !utIsEmpty(this.currentUser.ProfileImageID);
      }

      return false;
    },

    profileRouteArray(): Array<IRouteConfig> {
      return [
        this.profileRouteShow(this.$i18n.locale),
      ];
    },

    userImageIsLoading(): boolean {
      if (this.currentUser) {
        return this.currentUser.ProfileImageLoading;
      }

      return false;
    },

    userImageAltText(): string {
      if (this.currentUser) {
        return this.currentUser.ProfileImageName;
      }

      return '';
    },

    userImageUrl(): string {
      if (this.currentUser) {
        return this.currentUser.userImageURL();
      }

      return '';
    },
  },

  methods: {
    async removeUserImage(): Promise<any> {
      await MUser._removeProfileImage();
    },

    async uploadUserImage(fileList): Promise<any> {
      let file = fileList;

      if (_isArrayLikeObject(fileList)) {
        file = fileList[0];
      }

      await MUser._setProfileImage(file);
    },
  },
});
