














































































































import BaseInputField from '@/components/base/BaseInputField.vue';
import BaseInputMask from '@/components/base/BaseInputMask.vue';
import BaseRow from '@/components/base/BaseRow.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import BaseSideLabel from '@/components/base/BaseSideLabel.vue';
import BaseTextArea from '@/components/base/BaseTextArea.vue';
import BaseToggle from '@/components/base/BaseToggle.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import LayoutSection from '@/layouts/LayoutSection.vue';
import Vue, { VueConstructor } from 'vue';
import { mixinModal } from '@/components/mixins/modal';
import { MOrganization } from '@/models/MOrganization';
import { MSettings } from '@/models/MSettings';
import { MUser } from '@/models/MUser';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'TabSettings',

  components: {
    BaseInputField,
    BaseInputMask,
    BaseRow,
    BaseSelect,
    BaseSideLabel,
    BaseTextArea,
    BaseToggle,
    LayoutHeader,
    LayoutSection,
  },

  mixins: [
    mixinModal,
  ],

  data() {
    return {
      focused: false,
    };
  },

  computed: {
    currentOrganization: {
      get(): MOrganization {
        return MOrganization.query().with('Address').with('InvoiceAddress').first();
      },

      async set(organization: MOrganization): Promise<any> {
        await MOrganization.update(organization);

        MOrganization._update(this.currentOrganization);
      },
    },

    currentSettings: {
      get(): MSettings {
        return MSettings.find(this.currentOrganization.SettingsId);
      },

      async set(value: MSettings): Promise<any> {
        await MSettings.update(value);
      },
    },

    currentSettingsProductList(): Object {
      return this.layoutOptions.filter(
        option => option.id === this.currentSettings.ProductList,
      )[0];
    },

    currentUser(): MUser {
      return MUser.query().first();
    },

    layoutOptions(): Array<any> {
      return [
        {
          id: 'grid',
          name: this.$t('common.layout.grid'),
        },
        {
          id: 'list',
          name: this.$t('common.layout.list'),
        },
      ];
    },
  },

  watch: {
    async focused(): Promise<any> {
      if (!this.focused) {
        setTimeout(
          async() => {
            if (!this.focused) {
              await MSettings._update(this.currentSettings);
            }
          },
          250,
        );
      }
    },
  },

  methods: {
    async updateSettings(property: string, value: string): Promise<any> {
      this.currentSettings = {
        ...this.currentSettings,
        [`${property}`]: value,
      };
    },
  },
});
