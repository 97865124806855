













































import BaseFlyOut from '@/components/base/BaseFlyOut.vue';
import BaseLoader from '@/components/base/BaseLoader.vue';
import BaseSvgIcon from '@/components/base/BaseSvgIcon.vue';
import Upload from '@/components/Upload.vue';
import { ACTION, actionMap } from '@/utils/gridActions/actionMap';
import { CONTEXT_NAME } from '@/utils/contextName';
import { IBaseGridActionListItem } from './base/grid/typings/interface/IBaseGridActionListItem';
import { IMAGE_MIME_TYPE_WHITE_LIST } from '@/utils/fileTypes';
import { POSITION } from '@/utils/positions';

export default {
  name: 'ImageChangeable',

  components: {
    BaseFlyOut,
    BaseLoader,
    BaseSvgIcon,
    Upload,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    imageIsPresent: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    mimeTypeList: {
      type: Array,
      default: function() {
        return IMAGE_MIME_TYPE_WHITE_LIST;
      },
    },
  },

  data() {
    return {
      CONTEXT_NAME,
      isActive: false,
      POSITION,
    };
  },

  computed: {
    classList() {
      return {
        'image-changeable--active': this.isActive,
        'image-changeable--disabled': this.isDisabled,
      };
    },

    imageFlyOutActionList(): Array<IBaseGridActionListItem> {
      if (this.imageIsPresent) {
        return [
          {
            ...actionMap.get(ACTION.REPLACE),
            label: 'image.action.replace',
          },
          {
            ...actionMap.get(ACTION.DELETE),
            label: 'image.action.delete',
          },
        ];
      }

      return [
        {
          ...actionMap.get(ACTION.REPLACE),
          icon: 'arrow-up-line',
          label: 'image.action.upload',
          name: 'upload',
        },
      ];
    },

    isDisabled() {
      return this.disabled || this.loading;
    },
  },

  methods: {
    onFileSelected(payload): void {
      this.$emit(
        'change:image',
        payload.fileList[0],
      );
    },

    onFlyOutActionClicked(action: string): void {
      if (action === 'replace') {
        this.$refs.imageUpload.openFileDialog();
      }

      if (action === 'delete') {
        this.$emit('delete:image');
      }
    },

    onFlyOutHidden(): void {
      this.isActive = false;
    },

    onFlyOutShown(): void {
      this.isActive = true;
    },

    openImageFlyOut(): void {
      this.$refs.imageFlyOut.setVisibility(true);
    },
  },
};
