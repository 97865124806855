


























































































































































































import BaseAddress from '@/components/base/BaseAddress.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import BaseImage from '@/components/base/BaseImage.vue';
import BaseInputField from '@/components/base/BaseInputField.vue';
import BaseRow from '@/components/base/BaseRow.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import BaseSideLabel from '@/components/base/BaseSideLabel.vue';
import ImageChangeable from '@/components/ImageChangeable.vue';
import LayoutSection from '@/layouts/LayoutSection.vue';
import uuid4 from 'uuid/v4';
import Vue, { VueConstructor } from 'vue';
import { find as _find, isArrayLikeObject as _isArrayLikeObject } from 'lodash';
import { directiveDroppable, UPLOAD_TYPE } from '@/directives/droppable';
import { FILE_MIME_TYPE } from '@/utils/fileTypes';
import { IAddress, initAddress } from '@/typings/interface/IAddress';
import { ISettings } from '@/typings/interface/ISettings';
import { language } from '@/utils/language';
import { MAddress } from '@/models/MAddress';
import { mixinModal } from '@/components/mixins/modal';
import { MOrganization } from '@/models/MOrganization';
import { MSettings } from '@/models/MSettings';
import { MUser } from '@/models/MUser';
import { SELECTIONSTATE } from '@/utils/states';
import { userChangePassword } from '@/views/profile/routes';
import { utIsEmpty } from '@/utils/empty';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'TabOrganization',

  directives: {
    droppable: directiveDroppable,
  },

  components: {
    BaseAddress,
    BaseCheckbox,
    BaseImage,
    BaseInputField,
    BaseRow,
    BaseSelect,
    BaseSideLabel,
    ImageChangeable,
    LayoutSection,
  },

  mixins: [
    mixinModal,
  ],

  data() {
    return {
      languageOptions: language,
      logoIsLoading: false,
      profileImageAcceptedMimeTypeList: [
        FILE_MIME_TYPE.JPG,
        FILE_MIME_TYPE.PNG,
      ],
      stationeryIsLoading: false,
      uploadMode: UPLOAD_TYPE.SINGLE,
    };
  },

  computed: {
    currentLogoIsPresent(): boolean {
      if (this.currentSettings) {
        return !utIsEmpty(this.currentSettings.LogoUrl);
      }

      return false;
    },

    currentStationeryIsPresent(): boolean {
      if (this.currentSettings) {
        return !utIsEmpty(this.currentSettings.StationeryUrl);
      }

      return false;
    },

    currentUser(): MUser {
      return MUser.query().first();
    },

    currentOrganization: {
      get(): MOrganization {
        return MOrganization.query().with('Address').with('InvoiceAddress').first();
      },

      async set(organization: MOrganization): Promise<any> {
        await MOrganization.update(organization);

        MOrganization._update(this.currentOrganization);
      },
    },

    currentOrganizationAddress: {
      get(): IAddress {
        if (this.currentOrganization.AddressId) {
          return MAddress.find(this.currentOrganization.AddressId);
        }

        return initAddress();
      },

      set(address: IAddress) {
        if (!utIsEmpty(address)) {
          if (address?.Id !== null) {
            MAddress.update({
              data: address,
            });

            MOrganization._update(this.currentOrganization);
          } else {
            const addressId = uuid4();

            MAddress.insertOrUpdate({
              data: {
                ...address,
                Id: addressId,
                OwnerID: this.currentOrganization.Id,
              },
            });

            this.currentOrganization = {
              ...this.currentOrganization,
              AddressId: addressId,
            };
          }
        }
      },
    },

    currentOrganizationInvoiceAddress: {
      get(): IAddress {
        if (this.currentOrganization.InvoiceAddressId) {
          return MAddress.find(this.currentOrganization.InvoiceAddressId);
        }

        return initAddress();
      },

      set(address: IAddress) {
        if (!utIsEmpty(address)) {
          if (address?.Id !== null) {
            MAddress.update({
              data: address,
            });

            MOrganization._update(this.currentOrganization);
          } else {
            MAddress.insertOrUpdate({
              data: {
                ...address,
                Id: uuid4(),
                OwnerID: this.currentOrganization.Id,
              },
            });
          }
        }
      },
    },

    currentSettings: {
      get(): MSettings {
        return MSettings.find(this.currentOrganization.SettingsId);
      },

      async set(settings): Promise<any> {
        await MSettings.update(settings);

        await MSettings._update(this.currentSettings);

        this.$i18n.locale = settings['Language'];

        localStorage.setItem('locale', settings['Language']);

        window.location.reload();
      },
    },

    currentSettingsLanguage(): object {
      if (!utIsEmpty(this.currentSettings?.Language)) {
        return _find(
          language,
          (lang) => {
            return lang.code === this.currentSettings.Language;
          },
        );
      }

      return {
        code: '',
        nativeName: '',
      };
    },

    notSameAsAddress(): boolean {
      return this.sameAsAddress !== SELECTIONSTATE.CHECKED;
    },

    logoAltText(): string {
      if (this.currentSettings) {
        return this.currentSettings?.LogoAltText;
      }

      return '';
    },

    logoUrl(): string {
      if (this.currentSettings) {
        return this.currentSettings?.organizationImageURL();
      }

      return '';
    },

    sameAsAddress: {
      get() {
        if (this.currentOrganization?.AddressId === this.currentOrganization?.InvoiceAddressId) {
          return SELECTIONSTATE.CHECKED;
        }

        return SELECTIONSTATE.UNCHECKED;
      },

      async set(value): Promise<any> {
        if (value === SELECTIONSTATE.CHECKED) {
          if (this.currentOrganization?.InvoiceAddressId) {
            MAddress.delete(this.currentOrganization.InvoiceAddressId);
          }

          this.currentOrganization = {
            ...this.currentOrganization,
            InvoiceAddressId: this.currentOrganization.AddressId,
          };
        } else {
          const addressId = uuid4();

          this.currentOrganization = {
            ...this.currentOrganization,
            InvoiceAddressId: '',
          };

          await MAddress.insertOrUpdate({
            data: {
              ...initAddress(),
              Id: addressId,
              OwnerID: this.currentOrganization.Id,
            },
          });

          this.currentOrganization = {
            ...this.currentOrganization,
            InvoiceAddressId: addressId,
          };
        }
      },
    },

    stationeryAltText(): string {
      if (this.currentSettings) {
        return this.currentSettings?.StationeryAltText;
      }

      return '';
    },

    stationeryUrl(): string {
      if (this.currentSettings) {
        return this.currentSettings?.organizationImageURL('Stationery');
      }

      return '';
    },
  },

  methods: {
    async openChangePasswordModal(): Promise<any> {
      this.$router.push(userChangePassword(this.$i18n.locale));
    },

    async removeImage(imageType: string = 'Logo'): Promise<any> {
      if (imageType === 'Stationery') {
        this.stationeryIsLoading = true;
      } else {
        this.logoIsLoading = true;
      }

      await MSettings._deleteImage(imageType);

      if (imageType === 'Stationery') {
        this.stationeryIsLoading = false;
      } else {
        this.logoIsLoading = false;
      }
    },

    async uploadImage(fileList: File, imageType: string = 'Logo'): Promise<any> {
      if (imageType === 'Stationery') {
        this.stationeryIsLoading = true;
      } else {
        this.logoIsLoading = true;
      }

      let file = fileList;

      if (_isArrayLikeObject(fileList)) {
        file = fileList[0];
      }

      await MSettings._uploadImage(file, imageType);

      if (imageType === 'Stationery') {
        this.stationeryIsLoading = false;
      } else {
        this.logoIsLoading = false;
      }
    },

    async updateSettings(property: string, value: string): Promise<any> {
      this.currentSettings = {
        ...this.currentSettings,
        [`${property}`]: value,
      };
    },

    async updateOrganization(property: string, value: string): Promise<any> {
      this.currentOrganization = {
        ...this.currentOrganization,
        [`${property}`]: value,
      };
    },
  },
});
