import { AlertConfirm } from '@/components/mixins/alert';
import {
  ALLOWED_FILE_TYPE,
  fileTypes,
  IMAGE_MIME_TYPE_WHITE_LIST,
  joinedArray,
  VISUAL_MIME_TYPE_WHITE_LIST,
} from '@/utils/fileTypes';
import { i18n } from '@/locales/i18n';
import { utIsEmpty } from '@/utils/empty';

const locale = localStorage.getItem('locale');

const DRAG_EVENT = {
  DRAG_ENTER: 'dragenter',
  DRAG_LEAVE: 'dragleave',
  DRAG_OVER: 'dragover',
  DROP: 'drop',
} as const;

type TDragEvent = keyof typeof DRAG_EVENT;

export const UPLOAD_TYPE = {
  SINGLE: 'single',
  MULTI: 'multi',
} as const;

type TUploadType = keyof typeof UPLOAD_TYPE;

const preventDefaultAndStopPropagation = (event) => {
  event.preventDefault();

  event.stopPropagation();
};

let addDragOverClass = null;
const removeDragOverClass = null;
let removeDragOverClassOnTargetOnly = null;
let handleDroppedFiles = null;
let mimeTypeList = VISUAL_MIME_TYPE_WHITE_LIST;

const defaultMaxFileSize = 25 * 1024 * 1024;

const hasValidFileSize = (
  file: File,
): boolean => {
  return !(file.size > defaultMaxFileSize);
};

const hasValidMimeType = (
  file: File,
  mimeTypeList,
): boolean => {
  if (!utIsEmpty(mimeTypeList)) {
    return (mimeTypeList.some(
      mimeType => mimeType === file.type)
    );
  }

  return true;
};

const maxFileSizeToMb = (): number => {
  return defaultMaxFileSize / 1024 / 1024;
};

const checkFileList = (fileList, mimeTypeList) => {
  const fileListArray: Array<File> = Array.from(fileList);

  const filesToUpload = [];

  let isFileListValid = true;

  let alertMessage = '';
  const alertFileSizeMessageList = [];
  const alertFileTypeMessageList = [];

  fileListArray.forEach(
    (file: File) => {
      if (!hasValidFileSize(file)) {
        isFileListValid = false;

        alertFileSizeMessageList.push(
          `"${file.name}"`,
        );
      }

      if (!hasValidMimeType(file, mimeTypeList)) {
        isFileListValid = false;

        alertFileTypeMessageList.push(
          `"${file.name}"`,
        );
      }

      if (hasValidFileSize(file) && hasValidMimeType(file, mimeTypeList)) {
        filesToUpload.push(file);
      }
    },
  );

  if (!utIsEmpty(alertFileTypeMessageList)) {
    const fileTypeList = mimeTypeList.map(
      type => fileTypes[type].alertName,
    );

    const fileTypeMessage = String(i18n(locale).t(
      'validation.upload.wrongFileType',
      {
        fileList: joinedArray(
          alertFileTypeMessageList,
          ', ',
          ` ${i18n(locale).tc('common.property.and')} `,
        ),
        fileTypeList: joinedArray(
          fileTypeList,
          ', ',
          ` ${i18n(locale).tc('common.property.or')} `,
        ),
      },
    ));

    alertMessage += `<span class="alert-list">${fileTypeMessage}</span>`;
  }

  if (!utIsEmpty(alertFileSizeMessageList)) {
    const fileSizeMessage = String(i18n(locale).t(
      'validation.upload.exceededMaxFileSize',
      {
        maxFileSize: maxFileSizeToMb(),
        fileList: joinedArray(
          alertFileSizeMessageList,
          ', ',
          ` ${i18n(locale).tc('common.property.and')} `,
        ),
      },
    ));

    alertMessage += `<span class="alert-list">${fileSizeMessage}</span>`;
  }

  if (!utIsEmpty(alertMessage)) {
    AlertConfirm.fire({
      confirmButtonText: String(i18n(locale).tc('common.action.ok')),
      showCancelButton: false,
      showConfirmButton: true,
      html: alertMessage,
    });
  }

  if (filesToUpload.length === 0) {
    return {
      hasFilesToUpload: false,
      fileList: [],
    };
  }

  return {
    hasFilesToUpload: true,
    newFileList: filesToUpload,
  };
};

export const directiveDroppable = {
  bind(element, binding) {
    if (
      binding.arg === UPLOAD_TYPE.MULTI ||
      binding.arg === UPLOAD_TYPE.SINGLE
    ) {
      element.dragEvents = [
        DRAG_EVENT.DRAG_ENTER,
        DRAG_EVENT.DRAG_LEAVE,
        DRAG_EVENT.DROP,
      ];

      addDragOverClass = () => {
        element.classList.add(DRAG_EVENT.DRAG_OVER);

        const modifiers = Object.keys(binding.modifiers);
        const modifierLength = modifiers.length;

        if (modifierLength > 0) {
          for (let i = 0; i < modifierLength; i++) {
            if (modifiers[i] === ALLOWED_FILE_TYPE.VISUAL) {
              mimeTypeList = VISUAL_MIME_TYPE_WHITE_LIST;
            } else if (modifiers[i] === ALLOWED_FILE_TYPE.PROFILE) {
              mimeTypeList = IMAGE_MIME_TYPE_WHITE_LIST;
            } else {
              element.classList.add(`${DRAG_EVENT.DRAG_OVER}--${modifiers[i]}`);
            }
          }
        }
      };

      removeDragOverClassOnTargetOnly = (event) => {
        if (event.target === element) {
          element.classList.remove(DRAG_EVENT.DRAG_OVER);

          const modifiers = Object.keys(binding.modifiers);
          const modifierLength = modifiers.length;

          if (modifierLength > 0) {
            for (let i = 0; i < modifierLength; i++) {
              element.classList.remove(`${DRAG_EVENT.DRAG_OVER}--${modifiers[i]}`);
            }
          }
        }
      };

      handleDroppedFiles = (event) => {
        if (binding.arg === UPLOAD_TYPE.MULTI) {
          const fileList = event.dataTransfer.files;

          const { hasFilesToUpload, newFileList } = checkFileList(fileList, mimeTypeList);

          if (hasFilesToUpload) {
            binding.value(newFileList);
          }
        }

        if (binding.arg === UPLOAD_TYPE.SINGLE) {
          if (event.dataTransfer.files.length > 1) {
            AlertConfirm.fire({
              confirmButtonText: String(i18n(locale).tc('common.action.ok')),
              showCancelButton: false,
              showConfirmButton: true,
              html: String(i18n(locale).tc('validation.upload.onlySingleFile')),
            });
          } else {
            const fileList = event.dataTransfer.files;

            const { hasFilesToUpload, newFileList } = checkFileList(fileList, mimeTypeList);

            if (hasFilesToUpload) {
              binding.value(newFileList);
            }
          }
        }

        element.classList.remove(DRAG_EVENT.DRAG_OVER);

        const modifiers = Object.keys(binding.modifiers);
        const modifierLength = modifiers.length;

        if (modifierLength > 0) {
          for (let i = 0; i < modifierLength; i++) {
            element.classList.remove(`${DRAG_EVENT.DRAG_OVER}--${modifiers[i]}`);
          }
        }
      };

      element.addEventListener(
        DRAG_EVENT.DRAG_ENTER,
        addDragOverClass,
      );

      element.addEventListener(
        DRAG_EVENT.DRAG_LEAVE,
        removeDragOverClassOnTargetOnly,
      );

      element.addEventListener(
        DRAG_EVENT.DRAG_OVER,
        preventDefaultAndStopPropagation,
      );

      element.addEventListener(
        DRAG_EVENT.DROP,
        handleDroppedFiles,
      );
    }
  },

  unbind(element, binding) {
    if (
      binding.arg === UPLOAD_TYPE.MULTI ||
      binding.arg === UPLOAD_TYPE.SINGLE
    ) {
      element.removeEventListener(
        DRAG_EVENT.DRAG_ENTER,
        addDragOverClass,
      );

      element.removeEventListener(
        DRAG_EVENT.DRAG_LEAVE,
        removeDragOverClass,
      );

      element.removeEventListener(
        DRAG_EVENT.DRAG_OVER,
        preventDefaultAndStopPropagation,
      );

      element.removeEventListener(
        DRAG_EVENT.DROP,
        handleDroppedFiles,
      );
    }
  },
};
