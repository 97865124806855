































import Vue, { VueConstructor } from 'vue';
import { AlertConfirm } from '@/components/mixins/alert';
import { utIsEmpty } from '@/utils/empty';

const defaultMaxFileSize = 25 * 1024 * 1024;

export default (Vue as VueConstructor<Vue>).extend({
  name: 'Upload',

  props: {
    disabledFileUpload: {
      type: Boolean,
      default: false,
    },

    maxFileSize: {
      type: Number,
      default: defaultMaxFileSize,
    },

    mimeTypeList: {
      type: Array,
      default: function() {
        return [];
      },
    },

    multiFile: {
      type: Boolean,
      default: false,
    },

    triggerOpenFileDialog: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    maxFileSizeToMb(): number {
      return this.maxFileSize / 1024 / 1024;
    },
  },

  watch: {
    triggerOpenFileDialog() {
      this.openFileDialog();
    },
  },

  methods: {
    hasValidFileSize(
      file: File,
    ): boolean {
      return !(file.size > this.maxFileSize);
    },

    hasValidMimeType(
      file: File,
    ): boolean {
      if (!utIsEmpty(this.mimeTypeList)) {
        return (this.mimeTypeList.some(
          mimeType => mimeType === file.type)
        );
      }

      return true;
    },

    onClick(): void {
      if (this.$slots.triggerFileUpload) {
        this.$emit('click');
      }

      if (this.$slots.triggerFileUploadInstantly) {
        this.openFileDialog();
      }
    },

    onFileChange(
      fieldName: string = 'File',
      fileList: FileList,
    ): void {
      const fileListArray: Array<File> = Array.from(fileList);

      let isFileListValid = true;

      if (fileListArray.length < 1) {
        this.$logger.warn('FileList is empty');

        return;
      }

      const alertMessage = fileListArray.map(
        (file: File) => {
          if (!this.hasValidFileSize(file)) {
            isFileListValid = false;

            return this.$t('validation.upload.exceededMaxFileSize', {
              maxFileSize: this.maxFileSizeToMb,
            });
          }

          if (!this.hasValidMimeType(file)) {
            isFileListValid = false;

            return this.$t(
              'validation.upload.wrongFileType',
              {
                fileName: file.name,
              },
            );
          }
        },
      );

      if (isFileListValid) {
        this.$emit(
          'select:file',
          {
            fileList: fileList,
          },
        );
      } else {
        AlertConfirm.fire({
          confirmButtonText: String(this.$t('common.action.ok')),
          showCancelButton: false,
          showConfirmButton: true,
          html: String(alertMessage),
        });
      }
    },

    openFileDialog() {
      if (!this.disabledFileUpload) {
        this.$refs.file.click();
      }
    },
  },

  mounted(): void {
    if (!this.$slots.triggerFileUpload && !this.$slots.triggerFileUploadInstantly) {
      this.$logger.error('Upload: One of the provided slots must be used.');
    }

    if (this.$slots.triggerFileUpload && this.$slots.triggerFileUploadInstantly) {
      this.$logger.error('Upload: Use only one of the provided slots.');
    }
  },
});
