import { ITab } from '@/typings/interface/ITab';
import { RouteConfig } from 'vue-router';

export const mixinTabList = {
  methods: {
    getRouterConfigTabList(routeList: Array<RouteConfig>): Array<ITab> {
      const tabList: Array<ITab> = [];

      const currentRoute = routeList.find(
        (route) => {
          if (typeof route.props === 'function') {
            const props = route.props(this.$route);

            return props !== undefined && props['mode'] === this.mode;
          }

          return route.props !== undefined && route.props['mode'] === this.mode;
        },
      );

      currentRoute.children.map(
        (childRoute: RouteConfig) => {
          tabList.push(
            {
              active: false,
              heading: this.$tc(childRoute.props['heading']),
              name: childRoute.name,
            },
          );
        },
      );

      return tabList;
    },
  },
};
