










































































import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import BaseInputField from '@/components/base/BaseInputField.vue';
import BaseRow from '@/components/base/BaseRow.vue';
import BaseSideLabel from '@/components/base/BaseSideLabel.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import LayoutSection from '@/layouts/LayoutSection.vue';
import Vue, { VueConstructor } from 'vue';
import { mixinModal } from '@/components/mixins/modal';
import { MOrganization } from '@/models/MOrganization';
import { MUser } from '@/models/MUser';
import { userChangePassword } from '@/views/profile/routes';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'TabProfile',

  components: {
    BaseButtonText,
    BaseInputField,
    BaseRow,
    BaseSideLabel,
    LayoutHeader,
    LayoutSection,
  },

  mixins: [
    mixinModal,
  ],

  computed: {
    currentOrganization(): MOrganization {
      return MOrganization.query().first();
    },

    currentUser(): MUser {
      return MUser.query().first();
    },
  },

  methods: {
    async openChangePasswordModal(): Promise<any> {
      this.$router.push(userChangePassword(this.$i18n.locale));
    },
  },
});
